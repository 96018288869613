@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Open+Sans:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboard-component {
  width: calc(100% - 240px);
  float: right;
  padding-top: 2.25rem;
  padding-left: 4rem;
  padding-right: 8rem;
}

@media screen and (max-width: 959px) {
  .dashboard-component {
    width: 100%;
    padding-left: 1rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 1280px) {
  .dashboard-component {
    padding-left: 1rem;
    padding-right: 2rem;
  }
}

